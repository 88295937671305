import * as React from 'react';

import { Button } from 'antd';

import { classNames } from 'util/utils';

import ErrorFallbackStyles from './ErrorFallback.module.less';

export const ErrorFallback = React.memo(({ error, info, resetErrorBoundary }) => {
  console.info(error?.stack?.split('\n')?.[0], info?.componentStack);

  return (
    <div className={classNames([ErrorFallbackStyles.ErrorBoundary])}>
      <div className={classNames([ErrorFallbackStyles.ErrorBoundaryAlert])} role="alert">
        <h2 className={classNames([ErrorFallbackStyles.ErrorBoundaryHeader])}>Something went wrong</h2>
        <br />
        <div className={classNames([ErrorFallbackStyles.ErrorBoundaryButtonWrapper])}>
          <Button
            type="primary"
            htmlType="button"
            className={classNames([ErrorFallbackStyles.ErrorBoundaryButton])}
            onClick={resetErrorBoundary}
          >
            Try again
          </Button>
        </div>
        {process.env.REACT_APP_ENV === 'development' && (
          <pre className={classNames([ErrorFallbackStyles.ErrorBoundaryError])}>
            <code>{error?.stack?.split('\n')?.[0]}</code>
            <code>{info?.componentStack}</code>
          </pre>
        )}
      </div>
    </div>
  );
});

ErrorFallback.displayName = 'ErrorFallback';
