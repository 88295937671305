import React, { useCallback } from 'react';

import { memoize } from 'lodash';
import _each from 'lodash/each';
import moment from 'moment';

import { resetCountAction } from 'routes/Transaction/Actions';

import { User } from 'entities/User';

import * as SearchAPI from 'services/SearchApi';
import { getLoginUser } from 'services/commonFunc';
import UtilService from 'services/util';

import { isArray, isFunction } from 'util/utils';
import { queryStringToObject } from 'util/utils';

import { DIAMOND_BLOCK, USERS_TYPE } from 'constants/Common';
import CommonApi from 'constants/CommonApi';
import { GRID_COLUMN } from 'constants/Inventory';
import OpenNotification from 'constants/OpenNotifications';

import { LIMIT } from './TableBack';
import { FILTER_COLUMNS } from './TableBack';
import { memoizedCellFactory } from './table-utils';

import closeBlackSvg from 'assets/svg/InventoryResult/close-black.svg';

export const searchFn = async ({ isPlatformSearch = false, ...object }, callback) => {
  // remove empty keys
  _each(object, (val, key) => {
    if (isArray(val) && val.length === 0) delete object[key];
    else if (isArray(val)) object[key] = val;
  });

  const [, res] = await SearchAPI.getSearchIds(object);

  if (res?.code === 'OK') {
    if (res?.data?.[0]?.count === 0 && !isPlatformSearch)
      return OpenNotification({ type: 'error', title: "No diamond's exist" });

    const searchIds = res.data.map((d) => d?.filter?.id);
    const NOSTONE = res?.data?.[0]?.count === 0 ? 'NOSTONE' : '';
    callback(false, { NOSTONE, searchIds });
  } else {
    callback(true);
  }
};

export const getSearchIds = (params) => {
  return new Promise((resolve) => {
    searchFn(params, (err, res) => {
      resolve([err, { notFound: res?.NOSTONE === 'NOSTONE', list: res?.searchIds }]);
    });
  });
};

export function getClientList(cb) {
  const config = { ...CommonApi.Account.paginate };

  UtilService.callApi(config, (err, res) => {
    cb(res?.code === 'OK' ? res : null);
  });
}

export const handleInsertMemo = async (available, user, cb) => {
  const config = { ...CommonApi.Memo.Insert };

  config.request = {
    user,
    blockType: DIAMOND_BLOCK.TYPE.MEMO,
    diamonds: available.map((x) => ({
      diamond: x.id,
      blockPricePerCarat: x.ctPr,
      blockAmount: x.amt,
      vnd: x.vnd,
      vStnId: x.vStnId,
    })),
  };

  await UtilService.callApi(config, (err, res) => {
    if (res && res?.code === 'OK') {
      OpenNotification({ type: 'success', title: res.message });
      cb(true);
    } else {
      cb(false);
    }
  });

  resetCountAction.emit();
};

export const handleMatchPair = async (filter, cb) => {
  const request = { filter, page: 1, limit: LIMIT };
  const [, res] = await UtilService.callApiAsync({ ...CommonApi.MatchPair.Insert, request });

  if (res?.code === 'OK') cb(res?.data?.filter?.id);
};

export const handlePlaceOrder = async (request, cb, removeDiamond = null, loadingFn) => {
  const requestConf = { ...CommonApi.DiamondConfirm.Request, request };
  loadingFn(true);

  await UtilService.callApi(requestConf, (err, res) => {
    if (isFunction(cb)) cb(res?.code === 'OK');

    if (isFunction(removeDiamond)) removeDiamond();

    if (res?.code === 'OK') OpenNotification({ type: 'success', title: res.message });
  });

  resetCountAction.emit();
};

const ColumnFilter = React.memo((props) => {
  const {
    column: { filterValue = [], setFilter, preFilteredRows, id, isSorted },
  } = props;
  const availableOptions = React.useMemo(() => {
    const options = new Set();

    preFilteredRows.forEach((row) => {
      if (!filterValue.includes(row.values[id])) {
        options.add(row.values[id]);
      }
    });
    return [...options.values()];
  }, [id, preFilteredRows, filterValue]);

  const toggleFilter = useCallback(
    (id) => {
      const selectedFilters = [...filterValue];

      if (selectedFilters.includes(id)) {
        selectedFilters.splice(
          selectedFilters.findIndex((x) => x == id),
          1,
        );
      } else {
        selectedFilters.push(id);
      }
      setFilter(selectedFilters);
    },
    [setFilter, filterValue],
  );

  return (
    <div className="llfilterMenuDropdown" style={isSorted ? { borderTop: '1px solid #e9ebf0' } : {}}>
      {filterValue && filterValue.length > 0 && (
        <ul className="llfilterSelect">
          <div className="llSortingTitle">Selected Filter</div>
          {filterValue.map((x) => {
            return (
              <li key={`columnFilter_${id}${x}`}>
                {x}
                <div className="closeFilter" onClick={() => toggleFilter(x)}>
                  <img src={closeBlackSvg} alt="icon" />
                </div>
              </li>
            );
          })}
        </ul>
      )}
      {availableOptions && availableOptions.length > 0 && (
        <ul>
          <div className="llSortingTitle">Select Filter</div>
          {availableOptions.map((x) => {
            return (
              <li key={`columnFilter_${id}${x}`} onClick={() => toggleFilter(x)}>
                {x}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
});

// Define a custom filter filter function!
function filterArrayIncludes(rows, id, filterValue = []) {
  if (filterValue.length === 0) return rows;
  return rows.filter((row) => filterValue.includes(row.values[id]));
}
filterArrayIncludes.autoRemove = (val) => !val || !val.length;

export function isMobile() {
  return window.innerWidth <= 991 ? true : false;
}

export const fetchcolumn = async (cb, grid, options) => {
  const loginUser = getLoginUser();
  const user = new User({ current: true });

  const NOTUPCOMING = user.isSalesperson ? ['amt', 'ctPr', 'rapAvg', 'back'] : [];
  const NOTBASE =
    user.isSalesperson && !user.isInternalSalesperson
      ? ['baseBack', 'baseCtpr', 'baseAmt', 'cstPerBlw', 'cstPrCrt', 'totCst']
      : [];
  const NOTCST = user.isInternalSalesperson ? ['cstPerBlw', 'cstPrCrt', 'totCst'] : [];

  const requestConf = {
    ...CommonApi.Gridcolumn.list,
    request: { name: grid ? grid : GRID_COLUMN.GRID_DIAMOND_LIST_ADMIN },
  };
  const [, res] = await UtilService.callApiAsync(requestConf);

  if (res?.code !== 'OK') return cb([]);

  const columns = [];
  const columnList = isArray(res?.data?.columns) && res.data.columns.map((column) => ({ ...column }));

  columnList.forEach((column) => {
    if (column.isActive && !column.isStatic && column.field) {
      column.field = column.field.trim();
      column.title = column.title ? column.title.trim() : column.title;
      if (column.field === 'oldvStnId' && ![USERS_TYPE.ADMIN, USERS_TYPE.SUPER_ADMIN].includes(loginUser?.type)) return;
      if (column.field === 'reminderDate' && window.location.pathname.includes('reminder')) return;
      if (column.field === 'remarks' && options === 'note') column.title = 'Notes';
      if (
        column.field === 'box' &&
        ![USERS_TYPE.SUPER_ADMIN, USERS_TYPE.ADMIN, USERS_TYPE.INTERNAL_SELLER]?.includes(loginUser?.type)
      )
        return;

      if (NOTUPCOMING.includes(column.field) && window.location.pathname.includes('upcoming')) return;
      if (NOTBASE.includes(column.field)) return;
      if (NOTCST.includes(column.field)) return;

      const canFilter = FILTER_COLUMNS.includes(column.field);

      if (column.field === 'dna') column.link = null;

      columns.push({
        accessor: column.field,
        Header: column.title,
        id: column.field,
        desc: column.desc,
        link: column.link,
        sort: column.sort,
        Filter: canFilter ? ColumnFilter : <></>,
        filter: filterArrayIncludes,
        Cell: memoizedCellFactory(column, options),
      });
    }
  });

  cb(columns);
};

// async proxy for fetchcolumn
export const fetchcolumnAsync = (...args) => new Promise((resolve) => fetchcolumn(resolve, ...args));
// memoized async proxy for fetchcolumn
export const fetchcolumnAsyncMemo = memoize(fetchcolumnAsync, (...args) => JSON.stringify(args));

export const allSortObject = (columns) =>
  isArray(columns) &&
  Object.fromEntries(columns.filter(({ sort }) => sort).map(({ sort, Header }) => [`${sort}`, Header]));

export function getNewStatus(row) {
  const diff = moment() - moment(row.inDt);
  const newInvent = diff > 7 * 24 * 60 * 60 * 1000 || row.wSts === 'B' || row.wSts === 'U' ? false : true;
  return newInvent;
}

export const canadaForeverMarkFilter = () => {
  const type = queryStringToObject()?.type;
  let extraFilter = {};

  if (type === 'CANADA_MARK') {
    extraFilter = { isCm: ['CERT', 'ELIG'] };
  } else if (type === 'FOREVER_MARK') {
    extraFilter = { isFm: ['CERT', 'ELIG'] };
  }
  return extraFilter;
};
