/* eslint-disable */
import React, { Component } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import Embed from 'containers/Embed/Embed';

import DiamondLocationService from 'components/AddLocation/DiamondLocationService';
import DiamondDetailAction from 'components/DiamondDetail/Action';
import BasicDetail from 'components/DiamondDetail/BasicDetail';
import MagnifierImage from 'components/DiamondDetail/MagnifierImage';
import DownloadAction from 'components/DiamondListing/Actions/DownloadAction';
import ShareAction from 'components/DiamondListing/Actions/ShareAction';
import VideoPlayer from 'components/MediaPlayer/VideoPlayer';

import { LocalModuleContext } from 'contexts/Permissions';

import { StoneService } from 'services/StoneService';

import { formatCurrency, formatDecimal, formatFloat, formatNumber, isEmpty } from 'util/utils';

import { FILE_URLS, USERS_TYPE, getFileUrl, getPath } from 'constants/Common';

import { handlePrint } from '../DiamondListing/CommonPopupFunction';

import downloadZipSvg from 'assets/svg/diamondDetail/download-zip.svg';
import printSvg from 'assets/svg/diamondDetail/print.svg';
import shareSvg from 'assets/svg/diamondDetail/share.svg';
import Storage from 'services/storage';

class DiamondDetail extends Component {
  static contextType = LocalModuleContext;

  state = {
    tabIndex: 0,
    subIndex: 0,
    visible: false,
    btnName: '',
  };

  componentDidMount() {
    DiamondLocationService.getDiamondLocation(this.props.data, this.setState.bind(this));
  }

  render() {
    const data = this.props.data;
    const videoLink =
      data?.videoFile && data?.files?.videoFile.match('https://up.diacam360.com')
        ? data?.files?.videoFile + '/video'
        : data?.files?.videoFile;
    const module = this.context;
    let companyName;
    let sellerName;
    let userName;
    let locationListSellerName;
    if (data && data?.tracks?.length > 0 && data?.tracks[0].blockDetails?.length > 0) {
      companyName = data?.tracks[0].blockDetails[0]?.user?.companyName;
      sellerName = `${data?.tracks[0].blockDetails[0]?.seller?.firstName} ${data?.tracks[0].blockDetails[0]?.seller?.lastName}`;
      userName = `${data?.tracks[0].blockDetails[0]?.user?.firstName} ${data?.tracks[0].blockDetails[0]?.user?.lastName}`;
    }
    if (data && data?._location?.name) {
      locationListSellerName = data?._location?.name;
    }
    if (data && !data?._location?.name && this.state.destination && getPath() === 'search') {
      locationListSellerName = this.state.destination?.replace(' (SELLER)', '');
    }
    const urls = window.location.pathname?.split('/');
    const page = urls?.pop() + urls?.pop();
    const LOGIN_USER = Storage.get('user')?.type;
    const path = window.location.pathname;
    const isRemarkShow =
      (path.includes('/transaction/memo/') ||
        path.includes('/transaction/order/') ||
        path.includes('/transaction/location-list/')) &&
      [USERS_TYPE.ADMIN, USERS_TYPE.SUPER_ADMIN].includes(LOGIN_USER);
    const isRemarkFieldChange = path?.includes('/transaction/order/approved');

    const getSeller = (seller) => {
      if (page === 'receivedmemo' || page === 'issuedmemo') {
        if (data?.isSellerNull) return false;
        else {
          if (data?.locationStone) {
            return `| ${data?.locationStone?.name}`;
          } else {
            return seller;
          }
        }
      } else if (getPath() === 'search') {
        if (this.state?.locationData?.name) {
          return `| ${this.state?.locationData?.name}`;
        } else {
          return seller;
        }
      } else {
        return seller;
      }
    };

    const result = [
      // data?.locNm,
      // data?.vndNm && getSeller(`| ${data?.vndNm}`),
      // page !== 'receivedmemo' && data?.user?.companyName && `| ${data?.user?.companyName}`,
      // page !== 'receivedmemo' && data?.user && `- ${data?.user?.firstName} ${data?.user?.lastName}`,
      // !data?.vndNm && sellerName && getSeller('| ' + sellerName),
      // page !== 'receivedmemo' && !data?.user?.companyName && companyName && '| ' + companyName,
      // page !== 'receivedmemo' && !data?.user && userName && '- ' + userName,
      // !data?.vndNm && !sellerName && locationListSellerName && getSeller('| ' + locationListSellerName),
      this.state.source,
      this.state.destination,
    ]
      .filter(Boolean)
      .join(' | ');

    // console.log({ result }, { module });
    // console.log(data, this.state.source, this.state.destination);
    return (
      <div className="diamondDetailWrapper">
        <Tabs selectedIndex={this.state.tabIndex} onSelect={(tabIndex) => this.setState({ tabIndex: tabIndex })}>
          <TabList className="customerMaintab diamondDetailTopTab">
            <Tab>Details</Tab>
          </TabList>
          <div className="diamondDetailInner">
            <div className="diamondDetailBottomBox">
              <div className="diamondDetailBottomAction">
                <div className="d-flex">
                  <DiamondDetailAction
                    image={downloadZipSvg}
                    tooltip="Download"
                    placement="bottomLeft"
                    onClick={() => this.setState({ visible: !this.state.visible, btnName: 'download' })}
                    visible={this.state.visible}
                  />
                  <DiamondDetailAction
                    image={shareSvg}
                    tooltip="Share"
                    onClick={() => this.setState({ btnName: 'share', visible: !this.state.visible })}
                  />
                  {/* <DiamondDetailAction image={printSvg} tooltip="Print" onClick={() => handlePrint([data])} /> */}
                  {this.state.visible && (
                    <>
                      {this.state.btnName == 'download' && (
                        <DownloadAction
                          {...data}
                          checked={[data]}
                          onClose={() => this.setState({ visible: !this.state.visible })}
                        />
                      )}
                      {this.state.btnName == 'share' && (
                        <ShareAction
                          {...data}
                          checked={[data]}
                          onClose={() => this.setState({ visible: !this.state.visible })}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            <TabPanel className="diamondDetailMainTab">
              <div className="detailHalfWhite" />
              <div className="detailHalfGray" />
              <div className="diamondDetailLeft">
                <div>
                  <Tabs
                    selectedIndex={this.state.subIndex}
                    onSelect={(tabIndex) => this.setState({ subIndex: tabIndex })}
                  >
                    <TabList className="customerMaintab">
                      <Tab>Image</Tab>
                      <Tab>Video</Tab>
                      <Tab>Certificate</Tab>
                    </TabList>
                    <div className="diamondDetailInnerDetail">
                      <TabPanel>
                        {data.files?.img ? (
                          <div className="diamondDetailImageBox">
                            <MagnifierImage className="imageBlock" image={data.files?.img} />
                          </div>
                        ) : (
                          <div className="imageSliderFullBlock no-msg no-image">
                            <span>No Image Available</span>
                          </div>
                        )}
                        {/* <MagnifierImage className="imageBlock" image={data.files?.img} /> */}
                      </TabPanel>
                      <TabPanel>
                        {videoLink ? (
                          <div className="diamondDetailImageBox">
                            <iframe src={videoLink} title="vFile" aspectRatio="4:3" autoPlay loop controls responsive />
                          </div>
                        ) : (
                          <div className="imageSliderFullBlock no-msg no-image">
                            <span>No Video Available</span>
                          </div>
                        )}
                        {/* <iframe src={videoLink} title="vFile" aspectRatio="4:3" autoPlay loop controls responsive /> */}
                      </TabPanel>
                      <TabPanel>
                        {data.files?.certFile ? (
                          <div className="diamondDetailImageBox">
                            {data?.files?.certFile.split('.').pop()?.toLowerCase() === 'pdf' ? (
                              <iframe src={data.files?.certFile} height="100%" title="certi" />
                            ) : (
                              <MagnifierImage
                                data-role="imagemagnifier"
                                data-magnifier-mode="glass"
                                data-lens-type="circle"
                                data-lens-size="800"
                                image={data.files?.certFile}
                              />
                            )}
                          </div>
                        ) : (
                          <div className="imageSliderFullBlock no-msg no-image">
                            <span>No Certificate Available</span>
                          </div>
                        )}
                        {/* <iframe src={data.files?.certFile} height="100%" title="certi" /> */}
                      </TabPanel>
                    </div>
                  </Tabs>
                </div>
              </div>
              <div className="diamondDetailRight">
                <BasicDetail data={data} />

                <div className="diamondDetailMore">
                  <h2 className="detailSmallTitle">Basic Details</h2>
                  <div className="diamondDetailMoreHalf">
                    <ul>
                      <li>
                        <span>Original Stock No.:</span>
                        <span>{data?.oldvStnId ?? '-'}</span>
                      </li>
                      <li>
                        <span>Stock No:</span>
                        <span>{data?.vStnId ?? '-'}</span>
                      </li>
                      <li>
                        <span>Shape:</span>
                        <span>{data?.shpNm ?? '-'}</span>
                      </li>
                      <li>
                        <span>Carat:</span>
                        <span>{formatDecimal(data?.crt) ?? '-'}</span>
                      </li>
                      <li>
                        <span>Color:</span>
                        <span>{data?.isFcCol ? data?.fcColDesc : data?.colNm}</span>
                        {/* <span>{data?.colNm ?? '-'}</span> */}
                      </li>
                      <li>
                        <span>Clarity:</span>
                        <span>{data?.clrNm ?? '-'}</span>
                      </li>
                      <li>
                        <span>Lab:</span>
                        <span>{data?.lbNm ?? '-'}</span>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <span>Report No:</span>
                        <span>{data?.rptNo ?? '-'}</span>
                      </li>
                      <li>
                        <span>Cut:</span>
                        <span>{data?.cutNm ?? '-'}</span>
                      </li>
                      <li>
                        <span>Polish:</span>
                        <span>{data?.polNm ?? '-'}</span>
                      </li>
                      <li>
                        <span>Symmetry:</span>
                        <span>{data?.symNm ?? '-'}</span>
                      </li>
                      <li>
                        <span>Fluorescence:</span>
                        <span>{data?.fluNm ?? '-'}</span>
                      </li>
                      <li>
                        <span>COUNTRY:</span>
                        <span>{data?.countryNm ?? '-'}</span>
                      </li>
                      <li>
                        <span>MOUNTED:</span>
                        <span>{data?.mount ?? '-'}</span>
                      </li>
                    </ul>
                    <ul style={{ width: '100%' }}>
                      <li>
                        <span>Location:</span>
                        <span style={{ textTransform: 'capitalize' }}>
                          {result}
                          {/* {isEmpty(this.state.source)
                            ? '-'
                            : isEmpty(this.state.destination)
                            ? this.state.source
                            : `${this.state.source} | ${this.state.destination}`} */}
                          {/* {`${data?.locNm} ${data?.vndNm && `| ${data?.vndNm}`}  ${
                            data?.user?.companyName &&`| ${data?.user?.companyName}`
                          } ${data?.user && `| ${data?.user?.firstName} ${data?.user?.lastName}`} `} */}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="diamondDetailMore">
                  <h2 className="detailSmallTitle">Measurements</h2>
                  <div className="diamondDetailMoreHalf">
                    <ul>
                      <li>
                        <span>Length</span>
                        <span>{formatDecimal(data?.length) ?? '-'}</span>
                      </li>
                      <li>
                        <span>Width</span>
                        <span>{formatDecimal(data?.width) ?? '-'}</span>
                      </li>
                      <li>
                        <span>Height</span>
                        <span>{formatDecimal(data?.height) ?? '-'}</span>
                      </li>
                      <li>
                        <span>Table%</span>
                        <span>{formatDecimal(data?.tblPer) ?? '-'}</span>
                      </li>
                      <li>
                        <span>Depth%</span>
                        <span>{formatDecimal(data?.depPer) ?? '-'}</span>
                      </li>
                      <li>
                        <span>Girdle</span>
                        <span>{data?.girdleStr ?? '-'}</span>
                      </li>
                      {/*
                      <li>
                        <span>Star Length%</span>
                        <span>{formatDecimal(data?.strLn) ?? '-'}</span>
                      </li> */}
                    </ul>
                    <ul>
                      <li>
                        <span>Ratio</span>
                        <span>{formatDecimal(data?.ratio) ?? '-'}</span>
                      </li>
                      <li>
                        <span>Pav Angle</span>
                        <span>{formatDecimal(data?.pAng) ?? '-'}</span>
                      </li>
                      <li>
                        <span>Pav Height</span>
                        <span>{formatDecimal(data?.pHgt) ?? '-'}</span>
                      </li>
                      <li>
                        <span>Crown Angle</span>
                        <span>{formatDecimal(data?.cAng) ?? '-'}</span>
                      </li>
                      <li>
                        <span>Crown Height</span>
                        <span>{formatDecimal(data?.cHgt) ?? '-'}</span>
                      </li>
                      <li>
                        <span>Culet</span>
                        <span>{data?.cultNm ?? '-'}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="diamondDetailMore">
                  <h2 className="detailSmallTitle">Pricing Details</h2>
                  <div className="diamondDetailMoreHalf">
                    <ul>
                      <li>
                        <span>Rap ($)</span>
                        <span>{formatCurrency(data?.rap) ?? '-'}</span>
                      </li>
                      <li>
                        <span>Price/Carat</span>
                        <span>{formatCurrency(data?.ctPr) ?? '-'}</span>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <span>Discount</span>
                        <span>{isNaN(formatDecimal(data?.back)) ? '-' : `${formatDecimal(data?.back)}%`}</span>
                      </li>
                      <li>
                        <span>Amount</span>
                        <span>{formatCurrency(data?.amt) ?? '-'}</span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="diamondDetailMore">
                  <h2 className="detailSmallTitle">B G M DETAILS</h2>
                  <div className="diamondDetailMoreHalf">
                    <ul>
                      <li>
                        <span>Milky</span>
                        <span>{data?.mlkNm ?? '-'}</span>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <span>Shade</span>
                        <span>{data?.shdNm ?? '-'}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="diamondDetailMore">
                  <h2 className="detailSmallTitle">Other Information</h2>
                  <div className="diamondDetailMoreHalf">
                    <ul>
                      <li>
                        <span>Laser Ins</span>
                        <span>{data?.lsrInc ?? '-'}</span>
                      </li>
                      <li>
                        <span>Heart & Arrow</span>
                        <span>{data?.hANm ?? '-'}</span>
                      </li>
                      {isRemarkShow && (
                        <li>
                          <span>Remarks</span>
                          <span>
                            {(isRemarkFieldChange ? data?.remarks ?? data?.comment : data?.comment ?? data?.remarks) ||
                              '-'}
                          </span>
                        </li>
                      )}
                      {[USERS_TYPE.SUPER_ADMIN, USERS_TYPE.ADMIN, USERS_TYPE.INTERNAL_SELLER]?.includes(LOGIN_USER) && (
                        <li>
                          <span>Box</span>
                          <span>{data?.box ?? '-'}</span>
                        </li>
                      )}
                    </ul>
                    <ul>
                      {/* <li>
                        <span>Extra Facet</span>
                        <span>{data?.eFact ?? '-'}</span>
                      </li> */}
                      <li>
                        <span>Key To Symbol</span>
                        <span>{data?.kToSStr ?? '-'}</span>
                      </li>
                      <li>
                        <span>Lab Comments</span>
                        <span>{data?.lbCmt ?? '-'}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="diamondDetailMore">
                  <h2 className="detailSmallTitle">Inclusion</h2>
                  <div className="diamondDetailMoreHalf">
                    <ul>
                      <li>
                        <span>Black Table</span>
                        <span>{data?.blkTblNm ?? '-'}</span>
                      </li>
                      <li>
                        <span>Black Crown</span>
                        <span>{data?.blkSdNm ?? '-'}</span>
                      </li>

                      <li>
                        <span>White Table</span>
                        <span>{data?.wTblNm ?? '-'}</span>
                      </li>

                      <li>
                        <span>White Crown</span>
                        <span>{data?.wSdNm ?? '-'}</span>
                      </li>

                      {/* <span>Side Black</span>
                        <span>{formatDecimal(data?.blkSdNm) ?? '-'}</span>
                      </li>
                      <li>
                        <span>Table Inclusion</span>
                        <span>{formatDecimal(data?.wTblNm) ?? '-'}</span>
                      </li> */}
                      {/* <li>
                        <span>Side Inclusion</span>
                        <span>{formatDecimal(data?.wSdNm) ?? '-'}</span>
                      </li>
                      <li>
                        <span>Black Table</span>
                        <span>{data?.blkTbl ?? '-'}</span>
                      </li> */}
                    </ul>
                    <ul>
                      <li>
                        <span>Table Open</span>
                        <span>{data?.opTblNm ?? '-'}</span>
                      </li>
                      <li>
                        <span>Pavillion Open</span>
                        <span>{data?.opPavNm ?? '-'}</span>
                      </li>
                      <li>
                        <span>Crown Open</span>
                        <span>{data?.opCrwnNm ?? '-'}</span>
                      </li>
                      <li>
                        <span>Eye Clean</span>
                        <span>{data?.eClnNm ?? '-'}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </TabPanel>
          </div>
        </Tabs>
      </div>
    );
  }
}
export default DiamondDetail;
