import React from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { useSelector } from 'react-redux';
import { useTable } from 'react-table';

import { Drawer } from 'antd';
import findLastIndex from 'lodash/findLastIndex';
import groupBy from 'lodash/groupBy';

import Image from 'containers/Image';

import CheckBox from 'components/CheckBox';
import CircularProgress from 'components/CircularProgress';
import DiamondDetail from 'components/DiamondDetail';
import ListTableHeaderBack from 'components/DiamondListing/ListTableHeaderBack';
import NoDataShow from 'components/DiamondListing/NoDataShow';
import Status from 'components/DiamondListing/Status';
import TableGrouping from 'components/Inventory/TableGrouping';
import * as TableConfig from 'components/Inventory/table-config';
import * as TableUtils from 'components/Inventory/table-utils';

import { RowSelectService } from 'services/RowSelectService';
import { GROUP_KEY } from 'services/groupingArray';
import { Storage } from 'services/storage';

import {
  useBoolean,
  useAutoRef,
  useSelectedClient,
  useCurrentType,
  useFilters,
  useSortBy,
  useIsIntersecting,
} from 'util/hooks';
import { classNames, isEmpty, isArray, isNotEmpty, isFunction, isString, deepEquals } from 'util/utils';

import { FILE_URLS, ALLOW_ROW_SELECTION, ALLOW_ROW_HIGHLIGHT_BY_LAB } from 'constants/Common';
import { COMMON_URL, PREFIX_URL } from 'constants/CommonUrl';

import downloadInvoiceSvg from 'assets/images/pdf.png';
import sortingLightSvg from 'assets/svg/InventoryResult/sorting-light.svg';
import cameraSvg from 'assets/svg/camera.svg';
import noImageList from 'assets/svg/noImageList.svg';
import videoSvg from 'assets/svg/video.svg';

export const LAB_LINKS = TableConfig.LAB_LINKS;
export const canSortCommon = TableConfig.SORTABLE_COLUMNS;
export const LIMIT = TableConfig.PAGE_LIMIT;
export const FILTER_COLUMNS = TableConfig.FILTER_COLUMNS;
export const floatkeys = TableConfig.FLOAT_COLUMNS;
export const roundkeys = TableConfig.ROUND_COLUMNS;
export const DISPLAY_TOTAL = TableConfig.TOTAL_COLUMNS;
export const PRICE_COLUMNS = TableConfig.PRICE_COLUMNS;

const getStyles = (props, item, type, data = {}) => {
  return [
    props,
    {
      style: {
        textAlign: item.cellClass ? item.cellClass.replace('text-', '') : 'center',
        width: (item.width || '100') + 'px',
        fontWeight: type === 'cell' && TableConfig.BOLD_COLUMNS.includes(item?.id) ? '600' : '',
        color:
          type === 'cell' &&
          (item.id === 'vStnId' || item.id === 'dna' || (item.id === 'lbNm' && data?.lbNm !== 'NONE') || item.link)
            ? '#008cba'
            : TableConfig.PRICE_COLUMNS.includes(item?.id)
            ? '#96a793'
            : TableConfig.BASE_PRICE_COLUMNS.includes(item?.id)
            ? '#b3929c'
            : TableConfig.COST_PRICE_COLUMNS.includes(item?.id)
            ? '#838dad'
            : '',
        // textDecoration: type == 'cell' && item.id == 'dna' && 'underline',
      },
    },
  ];
};
const headerProps = (props, { column }) => getStyles(props, column, 'header');
const cellProps = (props, { cell }) => getStyles(props, cell.column, 'cell', cell?.row?.original);

export const retEditClient = (accountId) => () =>
  !isEmpty(accountId) && Storage.set('search-account', accountId) && window.open(`/${PREFIX_URL}/client/kyc/basic`);

export const NoDataFound = ({ loading, length }) =>
  loading ? <NoDataShow message={<CircularProgress />} /> : length === 0 ? <NoDataShow /> : <></>;

const Resource = React.memo((props) => {
  const { row } = props;
  return (
    <Image
      className="diamondListImage"
      src={
        window.location?.pathname.includes('predefine-match-pair')
          ? FILE_URLS.img.replace(/[*]{3}/g, row?.vStnId)
          : FILE_URLS.JPG_ALT.replace(/[*]{3}/g, row?.vStnId)
      }
      bgDark
    >
      <img src={noImageList} />
    </Image>
  );
});
Resource.displayName = 'Resource';

const ActionHeader = React.memo((props) => {
  const { displayedRows, disabled } = props;
  const { currentType, noCheckBox } = props;

  const isSelected = useSelector(
    (state) => state.diamondData.selectedRowIds?.[currentType]?.length === displayedRows?.length,
  );

  const isIndeterminate = useSelector(
    (state) => !isSelected && Boolean(state.diamondData.selectedRowIds?.[currentType]?.length),
  );

  const handleChange = React.useCallback(() => {
    const list = displayedRows?.map?.((row) => ({ ...row, selectionKey: row?.selectionKey ?? row.id ?? row?._id }));

    isSelected
      ? RowSelectService.unSelectRows(currentType, list)
      : RowSelectService.selectRows(currentType, list, true);
  }, [displayedRows, isSelected, currentType]);

  return (
    <div className="selectActionIcon">
      {!noCheckBox && (
        <div className={'selectActionIconWrapper'}>
          <CheckBox
            key="selection_header"
            checked={isSelected}
            indeterminate={`${isIndeterminate}`}
            onClick={handleChange}
            disabled={disabled || isEmpty(displayedRows)}
          />
        </div>
      )}
    </div>
  );
});

const ActionCell = React.memo((props) => {
  const { row = {}, disabled, noStatus, pairStkNo } = props;
  const { currentType, liveStatus, soldStatus, noCheckBox, page } = props;

  const divRef = React.useRef(props);

  const isIntersecting = useIsIntersecting(divRef);
  const isIntersectingRef = useAutoRef(isIntersecting);

  const isSelected = useSelector(
    (state) => Boolean(state.diamondData.status?.[currentType]?.[row.selectionKey ?? row.id ?? row?._id]),
    (left, right) => (isIntersectingRef.current ? left === right : true),
  );

  const handleChange = React.useCallback(() => {
    const _row = { ...row, selectionKey: row.selectionKey ?? row.id ?? row?._id };
    if (row?.disableSelection) return;
    isSelected ? RowSelectService.unSelectRows(currentType, _row) : RowSelectService.selectRows(currentType, _row);
  }, [currentType, isSelected, row]);

  return (
    <div ref={divRef} className="selectActionIcon">
      {!noStatus && (
        <Status status={row?.wSts} showLive={liveStatus} soldIndication={soldStatus} isSold={row?.isSold} page={page} />
      )}
      {!noCheckBox && (
        <div
          className={'selectActionIconWrapper'}
          style={pairStkNo && row?.pairStkNo ? { bottom: row?.altCert ? '-31px' : '-14px' } : {}}
        >
          <CheckBox
            key={`selection_${row.id}`}
            checked={isSelected}
            onChange={handleChange}
            disabled={row.disableSelection || disabled}
          />
        </div>
      )}
    </div>
  );
});
ActionCell.displayName = 'ActionCell';

const Cell = React.memo((props) => {
  const { data, row, cell, grouppedRows, expandedList } = props;
  const path = window.location.pathname;
  const isRemarkFieldChange = path?.includes('/transaction/order/approved');

  const { clickHandler } = props;

  const handleClick = React.useCallback((e) => clickHandler(e, row, cell), [cell, clickHandler, row]);

  const similarStoneCount = data.length - (findLastIndex(data, (o) => o.isExactSearch) + 1);

  const rowSpan = React.useMemo(() => {
    if (window.location?.pathname.includes('predefine-match-pair') && cell.column.id === 'Details') {
      const { id, pairStkNo } = { ...row?.original };
      const rows = grouppedRows?.[pairStkNo];

      if (rows?.length === 2) {
        const expList = rows.filter((row) => row?.id !== rows?.[rows.length - 1]?.id && expandedList.includes(row?.id));
        if (!isEmpty(expList)) return 1;
        if (rows?.[0]?.id === id) return 2;
        return 0;
      }
    }
    return 1;
  }, [cell.column.id, expandedList, grouppedRows, row?.original]);

  if (rowSpan === 0) return null;

  return (
    <td {...cell.getCellProps(cellProps)} rowSpan={rowSpan} onClick={handleClick}>
      {cell.column.id === 'Details' ? (
        <Resource row={row.original} data={data} />
      ) : (
        <div>
          {findLastIndex(data, (o) => o.isExactSearch) === row?.index && (
            <span className="similarStoneLabel">Similar Stone ({similarStoneCount})</span>
          )}
          {row.original?.altCert && !TableConfig.ALT_COLUMNS.includes(cell.column.id) ? (
            <span style={{ display: 'flex', flexWrap: 'wrap', width: cell.column.id !== 'invoice' ? '100%' : '20px' }}>
              <span
                style={{ width: '100%' }}
                onClick={() => {
                  if (cell.column.id === 'lbNm' && row.original.lbNm && row.original.rptNo) {
                    TableUtils.viewStoneCertFile(row.original);
                  }
                }}
              >
                {cell.column.id === 'invoice' && row.original?.invoice ? (
                  <img src={downloadInvoiceSvg} style={{ width: '20px' }} />
                ) : (
                  cell.render('Cell') ?? null
                )}
              </span>
              {row.original.altCert?.map((alt, index) => (
                <span
                  onClick={() => {
                    if (cell.column.id === 'lbNm' && alt.lbNm && alt.rptNo) TableUtils.viewStoneCertFile(alt);
                  }}
                  key={index}
                  style={{ width: '100%' }}
                  className={
                    floatkeys.includes(cell.column.id) || roundkeys.includes(cell.column.id) ? 'numberValue' : null
                  }
                >
                  {TableUtils.formatValues(alt[cell.column.id], cell.column.id)}
                </span>
              ))}
            </span>
          ) : ['img'].includes(cell.column.id) ? (
            <img src={cameraSvg} style={{ width: '20px' }} />
          ) : ['invoice'].includes(cell.column.id) && row.original?.invoice ? (
            <img src={downloadInvoiceSvg} style={{ width: '20px' }} />
          ) : ['video'].includes(cell.column.id) ? (
            <img src={videoSvg} style={{ width: '20px' }} />
          ) : cell.column.id === 'back' &&
            window.location?.pathname.includes('watchlist') &&
            row.original.back !== row.original.prvBack &&
            row.original.prvBack ? (
            <div
              className={`newDiscount ${
                row.original.back < 0
                  ? row.original.back > row.original.prvBack
                    ? `red`
                    : `green`
                  : row.original.back < row.original.prvBack
                  ? `green`
                  : `red`
              }`}
            >
              <span>{row.original.back}</span> | <del>{row.original.prvBack} </del>
            </div>
          ) : cell.column.id === 'comment' && isRemarkFieldChange ? (
            <span>{row?.original?.remarks || row?.original?.comment}</span>
          ) : (
            cell.render('Cell') ?? null
          )}
        </div>
      )}
    </td>
  );
});
Cell.displayName = 'Cell';

const TrRow = React.memo((props) => {
  const { currentType, data, row, grouppedRows, expandedList } = props;
  const { dragDrop, nocheck, noCheckBox } = props;
  const [detailDrawer, setDetailDrawer] = useBoolean();

  const { inVoiceDownloadFn, moverow } = props;
  const { toggleIsExpanded } = props;

  const isExpanded = expandedList.includes(row.original.id);

  const DND_ITEM_TYPE = 'row';
  const dropRef = React.useRef(null);
  const dragRef = React.useRef(null);
  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover(item, monitor) {
      if (!dropRef.current) return;

      const dragIndex = item.index;
      const hoverIndex = row?.index;
      if (dragIndex === hoverIndex) return;

      const hoverBoundingRect = dropRef.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      const _row = data[dragIndex];

      if (data[dragIndex]?.sortingSequence) {
        const b = data[dragIndex]?.sortingSequence;
        data[dragIndex].sortingSequence = data[hoverIndex].sortingSequence;
        data[hoverIndex].sortingSequence = b;
      }

      let newlist = data.map((record) => ({ ...record }));
      newlist = [...newlist.slice(0, dragIndex), ...newlist.slice(dragIndex + 1, newlist.length)];
      newlist = [...newlist.slice(0, hoverIndex), _row, ...newlist.slice(hoverIndex, newlist.length)];
      if (moverow) moverow(newlist);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: DND_ITEM_TYPE, index: row?.index },
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });

  preview(drop(dropRef));
  drag(dragRef);

  const clickHandler = React.useCallback(
    (e, row, cell) => {
      switch (cell?.column?.id) {
        case 'selection':
          return;

        case 'vStnId':
          // return currentType == 'LocationList' ? null : setDetailDrawer.true();
          return setDetailDrawer.true();

        default:
          break;
      }
      if (['selection', 'dna'].includes(cell.column?.id)) return;

      // const isLayout = !isEmpty(row.original?.layoutNo) && `${row.original?.layoutNo}` !== '0';
      // const isMatchPair = !isEmpty(row.original?.pairStkNo) && `${row.original?.pairStkNo}` !== '0';

      if (isString(cell.column?.link)) {
        let field = cell.column.link.slice(cell.column.link.indexOf('$') + 1, cell.column.link.length);
        field = field.slice(0, field.indexOf('$'));
        window.open(cell.column.link.replace('$' + field + '$', row.original[field]));
        return;
      }

      if (!['invoice', 'dna', 'video', 'ftc', 'lbNm'].includes(cell.column.id)) {
        return toggleIsExpanded(row.original.id);
      }

      if (cell.column.id === 'invoice' && row.original?.invoice && isFunction(inVoiceDownloadFn))
        return inVoiceDownloadFn(row.original?.invoice);

      if (cell.column.id === 'video')
        return window.open(`${COMMON_URL.FRONT_URL}diamondfullview/${row.original.id ?? row.original.vStnId}`);

      if (cell.column.id === 'ftc' && cell.value) return window.open(`/ftc/${row.original.id ?? row.original.vStnId}`);

      if (isEmpty(row.original.altCert) && cell.column.id === 'lbNm' && row.original.lbNm && row.original.rptNo) {
        return TableUtils.viewStoneCertFile(row.original);
      }

      if (!TableConfig.NOCHECK_COLUMNS.includes(cell.column.id)) {
        if (nocheck || noCheckBox) return;
        const parentNode = e.currentTarget.parentNode;

        if (parentNode) {
          const checkboxRef = parentNode.getElementsByTagName('input');

          if (checkboxRef && checkboxRef.length > 0 && ALLOW_ROW_SELECTION) {
            checkboxRef[0].click();
          }
        }
      }
    },
    [inVoiceDownloadFn, currentType, setDetailDrawer, toggleIsExpanded, nocheck, noCheckBox],
  );

  const rowClassNames = React.useMemo(() => {
    const lab = !isEmpty(row.original?.lbNm) && `${row.original.lbNm}`.toUpperCase();
    return classNames([lab]);
  }, [row.original.lbNm]);

  const isLastExactSearch = React.useMemo(() => {
    return findLastIndex(data, (record) => record?.isExactSearch) === row?.index;
  }, [data, row?.index]);

  const rowStyle = React.useMemo(() => {
    const opacity = isDragging ? 0 : 1;
    return { opacity, ...(isLastExactSearch && { borderBottom: '3px solid #414143' }) };
  }, [isDragging, isLastExactSearch]);

  const rowClassAdd = window.location?.pathname.includes('transaction')
    ? row?.original?.selectionKey
    : row.original?.id;

  return (
    <>
      {dragDrop && (
        <div className="tableSortingTd" ref={dragRef}>
          <img src={sortingLightSvg} />
        </div>
      )}
      <tr
        {...row.getRowProps({
          'table-row': currentType + rowClassAdd,
        })}
        style={rowStyle}
        ref={dropRef}
        className={ALLOW_ROW_HIGHLIGHT_BY_LAB && rowClassNames}
      >
        {row.cells.map((cell) => (
          <Cell
            data={data}
            row={row}
            cell={cell}
            key={cell.column.id + '_cell'}
            clickHandler={clickHandler}
            grouppedRows={grouppedRows}
            inVoiceDownloadFn={inVoiceDownloadFn}
            isExpanded={isExpanded}
            expandedList={expandedList}
          />
        ))}
      </tr>
      {/* {isExpanded && <TableCollapse length={row.cells.length} data={row.original} inOrderRequest={inOrderRequest} />} */}
      <Drawer onClose={setDetailDrawer.false} visible={detailDrawer} wrapClassName="diamondDetailPopup" destroyOnClose>
        {detailDrawer && <DiamondDetail data={row.original} onClose={setDetailDrawer.false} diamondPopup />}
      </Drawer>
    </>
  );
});
TrRow.displayName = 'TrRow';

const TableInner = React.memo((props) => {
  const {
    initialSort,
    handleSort,
    canSort = true,
    FilterOption = true,
    areAllChecked = false,
    noGrp = false,
    nodots = false,
    nocheck = false,
    noSerNum = false,
    nostatus = false,
    noCheckBox = false,
    noStoneFormat = false,
    viewOrder = false,
    viewOrderParams = {},
    hideCheckbox = false,
    sort: _sort,
  } = props;

  const [currentType] = useCurrentType(props.currentType);

  const selectedClient = useSelectedClient();
  const preservedRowIdsRef = React.useRef([]);

  const sort = _sort ?? initialSort;
  const sortRef = useAutoRef(sort);

  const columns = React.useMemo(() => {
    if (!isArray(props?.columns) || isEmpty(props?.columns)) return [];

    const _columns = props.columns.map((column) => ({ ...column }));

    if (!noSerNum) {
      _columns.unshift({ id: 'srNo', accessor: 'srNo', Header: 'Sr. No' });
    }

    if (!nocheck) {
      _columns.unshift({
        id: 'selection',
        accessor: 'selection',
        Header({ data: displayedRows }) {
          return (
            <ActionHeader
              displayedRows={displayedRows}
              areAllChecked={areAllChecked}
              currentType={currentType}
              noCheckBox={noCheckBox}
            />
          );
        },
        Cell({ row }) {
          return (
            <ActionCell
              currentType={currentType}
              nocheck={nocheck}
              noCheckBox={noCheckBox}
              noStatus={nostatus}
              row={row.original}
              soldStatus={window.location?.pathname.includes('memo') || window.location?.pathname.includes('hold')}
              disabled={
                window.location?.pathname.includes('predefine-match-pair') ||
                window.location?.pathname.includes('single-stone/layout')
                  ? 'pairStkNo'
                  : 'groupNo' in row.original
              }
            />
          );
        },
      });
    }
    return _columns;
  }, [areAllChecked, currentType, noCheckBox, noSerNum, nocheck, nostatus, props?.columns]);

  const data = React.useMemo(() => {
    if (!isArray(props?.data)) return [];

    return props.data.map((record, index) => {
      if (!noSerNum) record.srNo = LIMIT * ((props.currentPage ?? 1) - 1) + (index + 1);
      if (!noStoneFormat) record = TableUtils.updateRecord(record);
      return { ...record };
    });
  }, [noSerNum, noStoneFormat, props?.currentPage, props.data]);

  const translatedSort = React.useMemo(() => {
    return (
      sort
        ?.map?.((item) => {
          return Object.entries(item).map(([id, desc]) => {
            const title = (() => {
              const col = isArray(columns) && columns.find((col) => [col?.accessor, col?.id].includes(id));
              const output = col?.Header ?? col?.title ?? id;
              return output;
            })();
            desc = `${desc}`.toUpperCase() === 'DESC';
            return { id, desc, title };
          })?.[0];
        })
        ?.filter?.(isNotEmpty) ?? []
    );
  }, [columns, sort]);

  const tableParams = React.useMemo(() => {
    return [
      {
        data,
        columns,
        initialState: { sortBy: translatedSort },
        manualSortBy: true,
        isMultiSortEvent: () => true,
        disableSortBy: !canSort,
      },
      useFilters,
      useSortBy,
    ];
  }, [canSort, columns, data, translatedSort]);

  const reactTable = useTable(...tableParams);
  const { getTableProps, getTableBodyProps, prepareRow } = reactTable;
  const { headerGroups, rows, setSortBy, toggleSortBy } = reactTable;
  const { sortBy, filters } = reactTable.state;

  const sortByRef = useAutoRef(sortBy);
  const [expandedList, setExpandedList] = React.useState([]);

  const toggleIsExpanded = React.useCallback(
    (id) =>
      setExpandedList((list) => {
        const index = list.findIndex((item) => item === id);
        index > -1 ? list.splice(index) : list.push(id);
        return [...list];
      }),
    [],
  );

  const getGroupTitle = React.useCallback(
    (data) => {
      const options = {
        user: props?.sortUser,
        date: props?.sortDate,
        country: props?.sortCountry,
        salesPerson: props?.sortSalesPerson,
        status: props?.sortStatus,
        sortStone: props?.sortStone,
      };
      const country = data?.countryNm || '';
      const salesPerson = data?.seller || '';
      const date = data?.userDate || data?.createdAt;
      const dateTitle = data?.dateTitle || '';
      const user = [
        data?.user?.account?.companyName || data?.user?.companyName,
        data?.userName,
        data?.userMobile,
        data?.userEmail,
      ]
        .filter(isNotEmpty)
        .map((item) => (isEmpty(item) ? '-' : item))
        .join(' | ');

      return options?.user
        ? user
        : options?.sortStone
        ? data.vStnId ?? '-'
        : options?.date
        ? `${dateTitle ? dateTitle : ''} ${date}`
        : props.groupStoneDate
        ? `${date} - ${data?.vStnId}`
        : props?.stageShow || options?.status
        ? `${data?.sSts ?? data?.blockCode}`
        : options?.country
        ? country
        : options?.salesPerson
        ? salesPerson
        : [`${dateTitle ? dateTitle + ' ' + date : date}`, user].filter(isNotEmpty).join(' | ');
    },
    [
      props.groupStoneDate,
      props?.sortCountry,
      props?.sortDate,
      props?.sortStone,
      props?.sortSalesPerson,
      props?.sortStatus,
      props?.sortUser,
      props?.stageShow,
    ],
  );

  const displayTotal = React.useMemo(() => {
    const totalColumns = props?.displayTotal ?? TableConfig.TOTAL_COLUMNS ?? [];
    return totalColumns.filter((id) => Boolean(columns.find((column) => column?.id === id)));
  }, [columns, props?.displayTotal]);

  const groupedRows = React.useMemo(() => {
    if (noGrp) return rows;
    rows.forEach((row) => prepareRow(row));
    const originalRows = rows.map((row) => row?.original);
    const key = isString(originalRows?.[0]?.[GROUP_KEY]) ? GROUP_KEY : 'groupNo';
    return groupBy(originalRows, key);
  }, [noGrp, prepareRow, rows]);

  React.useEffect(() => {
    const sortBy = sortByRef.current;
    if (!deepEquals(sortBy, translatedSort)) setSortBy(translatedSort);
  }, [setSortBy, sortByRef, translatedSort]);

  React.useEffect(() => {
    const sort = sortRef.current;
    const newSort = sortBy.map(({ id, desc }) => ({ [id]: desc ? 'DESC' : 'ASC' }));
    if (!deepEquals(sort, newSort)) void handleSort?.(newSort);
    if (!isArray(sort)) void handleSort?.(newSort);
  }, [handleSort, sortRef, sortBy]);

  React.useEffect(() => {
    if (!isEmpty(currentType) && !isEmpty(rows)) {
      const preparedRows = rows.map((row) => {
        prepareRow(row);
        const selectionKey = row.original.selectionKey ?? row.original.id;
        const isDefaultChecked = row.original.isDefaultChecked ?? preservedRowIdsRef.current.includes(selectionKey);
        return { ...row.original, isDefaultChecked };
      });

      const list = areAllChecked ? preparedRows : preparedRows.filter((row) => row.isDefaultChecked);

      !isEmpty(list)
        ? RowSelectService.selectRows(currentType, list, true)
        : RowSelectService.resetSelectedRows(currentType);

      preservedRowIdsRef.current = [];

      return () => {
        RowSelectService.resetSelectedRows(currentType);
      };
    }
  }, [areAllChecked, currentType, prepareRow, rows]);

  React.useEffect(() => {
    preservedRowIdsRef.current = RowSelectService.getSelectedRowIds(currentType);

    return () => {
      preservedRowIdsRef.current = [];
    };
  }, [currentType, selectedClient]);

  const dataIsGrouped = data?.some?.((record) => record.isHeader);
  const filtersAreApplied = !isEmpty(filters);

  return (
    <table {...getTableProps({ className: classNames([`${currentType}-table`]) })}>
      <thead>
        {headerGroups.map((headerGroup, index) => (
          <tr key={index} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, k) => (
              <th key={k} {...column.getHeaderProps(headerProps)}>
                {column.id === 'selection'
                  ? column.render('Header')
                  : column.id !== 'action' && (
                      <ListTableHeaderBack
                        toggleSortBy={toggleSortBy}
                        setSortBy={setSortBy}
                        sortBy={sortBy}
                        column={column}
                        filters={filters}
                        nodots={nodots}
                        FilterOption={FilterOption}
                      />
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        <DndProvider backend={HTML5Backend}>
          {!isArray(rows.length) &&
            rows.map((row, index) => {
              prepareRow(row);

              if (row.original.isHeader || dataIsGrouped) {
                // assign title if not present
                row.original._groupTitle_ = row.original.isHoldHeader
                  ? row.original.blockCode
                  : row.original._groupTitle_ ?? getGroupTitle(row.original);
              }

              const groupKey = !noGrp && row.original?.[GROUP_KEY];
              const groupRows = !noGrp && groupedRows?.[groupKey];
              const groupingTitle = row.original._groupTitle_;

              /**
               * if data is groupped,
               * figure out if current row is the first row in the grouped
               * IIFE: using return early pattern
               */
              const isHeader = (() => {
                // is row has isHeader flag
                if (row.original?.isHeader) {
                  return true;
                }

                // if data is grouped and current record has different title than previous record
                if (dataIsGrouped && filtersAreApplied) {
                  return row.original._groupTitle_ !== rows[index - 1]?.original?._groupTitle_;
                }

                // none of the previous conditions are true
                return false;
              })();

              /**
               * if data is groupped,
               * figure out if current row is the last row in the grouped
               * IIFE: using return early pattern
               */
              const isFooter = (() => {
                // is row has isFooter flag
                if (row.original?.isFooter) {
                  return true;
                }

                // if data is grouped and current record has different title than previous record
                if (dataIsGrouped && filtersAreApplied) {
                  return row.original._groupTitle_ !== rows[index + 1]?.original?._groupTitle_;
                }

                // none of the previous conditions are true
                return false;
              })();

              return (
                <React.Fragment key={row?.selectionKey ?? row?.id ?? index}>
                  {!noGrp && row.original.isOfferHeader && (
                    <TableGrouping
                      hideCheckbox={hideCheckbox}
                      groupingTitle={row?.original?.offerGrp}
                      toggleCollapse={() => props.toggleCollapse(!row.original.collapsed, row.original.offerGrp)}
                      collapsed={row.original.collapsed}
                      calc={row.original.calc}
                      columns={columns}
                      isOfferPopup
                      colspan={1}
                    />
                  )}
                  {!noGrp && isHeader && (
                    <TableGrouping
                      hideCheckbox={hideCheckbox}
                      viewOrder={viewOrder}
                      viewOrderParams={viewOrderParams}
                      groupRows={groupRows}
                      noCheckBox={nocheck || noCheckBox}
                      currentType={currentType}
                      totalDiamonds={row.original.totalDiamonds}
                      groupingTitle={groupingTitle}
                      row={row.original}
                    />
                  )}
                  <TrRow
                    row={row}
                    data={data}
                    grouppedRows={groupedRows}
                    currentType={currentType}
                    noCheckBox={noCheckBox}
                    nocheck={nocheck}
                    expandedList={expandedList}
                    toggleIsExpanded={toggleIsExpanded}
                    moverow={props.moverow}
                    inVoiceDownloadFn={props.inVoiceDownloadFn}
                    inOrderRequest={props.inOrderRequest}
                    dragDrop={props.dragDrop}
                  />
                  {!noGrp && isFooter && (
                    <TableGrouping
                      hideCheckbox={hideCheckbox}
                      displayTotal={displayTotal}
                      columns={columns}
                      row={row.original}
                      groupRows={groupRows}
                    />
                  )}
                </React.Fragment>
              );
            })}
        </DndProvider>
      </tbody>
    </table>
  );
});
TableInner.displayName = 'TableInner';

export default function Table(props) {
  const { data, loading } = props;

  return (
    <React.Fragment>
      <TableInner {...props} />
      {loading && <CircularProgress />}
      {!loading && isEmpty(data) && <NoDataShow />}
    </React.Fragment>
  );
}
