import { catchError } from './catchError';

// Object.seal(input);
// Object.preventExtensions(input);

export function freeze(input) {
  return catchError(
    () => {
      Object.freeze(input);
      return true;
    },
    () => {
      return false;
    },
  );
}

export function deepFreeze(input) {
  return catchError(
    () => {
      freeze(input);

      Object.getOwnPropertyNames(input).forEach((prop) => {
        catchError(() => {
          if (!Object.isFrozen(input[prop])) {
            deepFreeze(input[prop]);
          }
        });
      });

      return true;
    },
    () => {
      return false;
    },
  );
}
