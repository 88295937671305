import { catchError, isArray, isFunction } from 'util/utils';

function* iteratorGenerator(entries) {
  for (const entry of entries) {
    yield catchError(
      () => {
        let value = entry;

        if (isFunction(entry)) {
          value = entry();
        }

        if (isArray(entry)) {
          const [func, ...args] = entry;
          value = func(...args);
        }

        return value?.hasError ?? value;
      },
      () => true,
    );
  }
}

class When {
  constructor(...entries) {
    this.entries = [...entries];
  }

  validate() {
    this.result = { value: false, done: false };

    const iterator = iteratorGenerator(this.entries);
    let i = -1;

    while (!this.result?.done && !this.result?.value) {
      this.result = iterator.next();
      i++;
    }

    this.result.i = i;
    this.result.done = true;
    return this;
  }

  then(thenCallback) {
    this.result.done && !this.result.value && thenCallback();
    return this;
  }

  else(elseCallback) {
    this.result.done && this.result.value && elseCallback();
    return this;
  }

  thenRet(...args) {
    return () => this.then(...args);
  }

  elseRet(...args) {
    return () => this.else(...args);
  }
}

export function when(...args) {
  return new When(...args).validate();
}
