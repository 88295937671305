import { logWarn } from './logWarn';

export function catchError(func, onError) {
  const handleError = (error) => {
    logWarn(error);
    return onError?.(error);
  };

  try {
    const output = func?.();
    return output?.constructor?.name === 'Promise' ? output?.catch?.(handleError) : output;
  } catch (error) {
    return handleError(error);
  }
}
