import React from 'react';

export function useCounter(init = 0) {
  const [count, set] = React.useState(init);

  const inc = React.useCallback((cb = () => {}) => set((n) => ((n = n > 0 ? ++n : 1), cb(n), n)), []);
  const dec = React.useCallback((cb = () => {}) => set((n) => ((n = n > 0 ? --n : 0), cb(n), n)), []);

  React.useDebugValue(count);
  return [count, inc, dec];
}
