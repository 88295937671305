import React from 'react';

import { produce } from 'immer';

import { isObject, isFunction, isArray } from 'util/utils';

export function useCompositeState(initialState = undefined, replace = false) {
  const [state, _setState] = React.useState(initialState);
  const initialStateRef = React.useRef(initialState);
  const replaceRef = React.useRef(replace);

  const setState = React.useCallback((objectOrCallback) => {
    const hasCallback = isFunction(objectOrCallback);
    const callback = hasCallback ? objectOrCallback : undefined;
    const values = !hasCallback ? objectOrCallback : undefined;
    const replace = replaceRef.current;

    _setState((state) => {
      const input = callback ? produce(callback)(state) : values;
      if (isArray(state)) return replace ? input : [...state, ...input];
      if (isObject(state)) return replace ? input : { ...state, ...input };
      return input;
    });
  }, []);

  const resetState = React.useCallback(() => {
    _setState(initialStateRef.current);
  }, []);

  React.useDebugValue(state);
  return [state, setState, resetState];
}
