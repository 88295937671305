import { clone } from './clone';
import { hasKey } from './hasKey';
import { isArray } from './isArray';
import { isFunction } from './isFunction';
import { isObject } from './isObject';
import { typeOf } from './typeOf';

/**
 * Deep Compare two objects
 * @author Sagar Panchal <panchal.sagar@outlook.com>
 * @param {*} a Object to compare
 * @param {*} b Object to compare
 * @returns
 */
export function deepEquals(a, b) {
  if (typeOf(a, typeOf(b))) return false;

  if (isObject(a)) {
    const A = clone(a);
    const B = clone(b);

    Object.keys(A).forEach((key) => {
      if (typeof A[key] === 'undefined') delete A[key];
    });

    Object.keys(B).forEach((key) => {
      if (typeof B[key] === 'undefined') delete B[key];
    });

    if (Object.keys(A).length !== Object.keys(B).length) return false;
    if (Object.keys(A).find((key) => !hasKey(B, key))) return false;

    return Object.keys(A).reduce((equals, key) => {
      if (!equals) return false;
      if (deepEquals(A[key], B[key])) return equals;
      return false;
    }, true);
  }

  if (isArray(a)) {
    if (a.length !== b.length) return false;

    return a.reduce((equals, aItem) => {
      if (!equals) return false;
      if (b.find((bItem) => deepEquals(aItem, bItem))) return true;
      return false;
    }, true);
  }

  if (typeOf(a, 'Date') || (isFunction(a?.valueOf) && isFunction(b?.valueOf))) {
    return a.valueOf() === b.valueOf();
  }

  return a === b;
}
