import React from 'react';

import { useAutoRef } from 'util/hooks';
import { catchError, isHTMLElement, isNumber, isNumeric, resolve } from 'util/utils';

const getInput = (ref) => ref?.current?.inputRef?.input ?? ref?.current?.inputNumberRef?.input ?? ref?.current;

export function useNumberRange(refA, refB) {
  const [valueA, setValueA] = React.useState(() => getInput(refA)?.value);
  const [valueB, setValueB] = React.useState(() => getInput(refB)?.value);

  const valuesRef = useAutoRef([valueA, valueB]);

  const range = React.useMemo(() => {
    const list = [isNumber(valueA) ? valueA : undefined, isNumber(valueB) ? valueB : undefined];
    return isNumber(valueA) && isNumber(valueB) && valueB < valueA ? list.reverse() : list;
  }, [valueA, valueB]);

  const clear = React.useCallback(() => {
    setValueA(undefined);
    setValueB(undefined);

    catchError(() => {
      getInput(refA).value = '';
      getInput(refB).value = '';

      if (refA.current?.inputRef) refA.current.inputRef.rawInput = '';
      if (refB.current?.inputRef) refB.current.inputRef.rawInput = '';

      if (refA.current?.inputNumberRef) refA.current.inputNumberRef.rawInput = '';
      if (refB.current?.inputNumberRef) refB.current.inputNumberRef.rawInput = '';
    });
  }, [refA, refB]);

  const swapIfNecessary = React.useCallback(() => {
    const [valueA, valueB] = valuesRef.current;
    const inputA = getInput(refA);
    const inputB = getInput(refB);

    const allInputsAreInactive =
      isHTMLElement(inputA) && isHTMLElement(inputB)
        ? document.activeElement !== inputA && document.activeElement !== inputB
        : true;
    if (!allInputsAreInactive) return;

    const allValuesAreNumbers = isNumber(valueA) && isNumber(valueB);

    if (!allValuesAreNumbers) {
      if (!isNumber(valueA)) setValueA(undefined);
      if (!isNumber(valueB)) setValueB(undefined);
      return;
    }

    const endIsLesserThanStart = valueB < valueA;
    if (!endIsLesserThanStart) return;

    void (setValueA(valueB), setValueB(valueA));
  }, [refA, refB, valuesRef]);

  const handleAChange = React.useCallback((eventOrValue) => {
    const value = resolve(eventOrValue);
    if (isNumeric(value, false)) setValueA(value);
  }, []);

  const handleBChange = React.useCallback((eventOrValue) => {
    const value = resolve(eventOrValue);
    if (isNumeric(value, false)) setValueB(value);
  }, []);

  const handleABlur = React.useCallback(() => {
    setValueA((value) => (isNumber(value) ? value : undefined));
    setImmediate(() => swapIfNecessary());
  }, [swapIfNecessary]);

  const handleBBlur = React.useCallback(() => {
    setValueB((value) => (isNumber(value) ? value : undefined));
    setImmediate(() => swapIfNecessary());
  }, [swapIfNecessary]);

  const output = [
    React.useMemo(() => ({ from: valueA, to: valueB, range }), [range, valueA, valueB]),
    React.useMemo(() => ({ from: handleAChange, to: handleBChange }), [handleAChange, handleBChange]),
    React.useMemo(() => ({ from: handleABlur, to: handleBBlur }), [handleABlur, handleBBlur]),
    React.useMemo(() => ({ from: setValueA, to: setValueB, clear }), [clear]),
  ];

  React.useDebugValue(output[0]);
  return output;
}
