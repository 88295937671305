import * as React from 'react';

import { ErrorBoundary } from './ErrorBoundary';

export function withErrorBoundary(errorBoundaryProps) {
  return (Component) => {
    function WithErrorBoundary(props) {
      return React.createElement(ErrorBoundary, errorBoundaryProps, React.createElement(Component, { ...props }));
    }

    WithErrorBoundary.displayName = (() => {
      const name = Component.displayName || Component.name || 'Unknown';
      return `withErrorBoundary(${name})`;
    })();

    return WithErrorBoundary;
  };
}
